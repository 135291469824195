"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const Constants = require("./constants");
const lib_es2015_1 = require("@hodol/webapp-common/lib-es2015");
class Apis {
    constructor() {
        this.login = (entity) => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/login`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify(entity),
                redirect: 'follow',
            }, apiResponse => {
                switch (apiResponse.code) {
                    case lib_es2015_1.UsualResponseCode.LOGIN_FAILED:
                        alert('Failed to login');
                        return { isContinue: false, body: null };
                }
                return { isContinue: true, body: null };
            });
        });
        this.logout = () => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/${Constants.apiPathGroup}/logout`, {
                method: 'GET',
                headers: {
                    'Content-type': 'application/json',
                },
                redirect: 'follow',
                credentials: 'include',
            });
        });
        this.getOauthContexts = () => __awaiter(this, void 0, void 0, function* () {
            return yield (0, lib_es2015_1.fetchWrapper)(`/apis/oauths/contexts`, {
                method: 'GET',
                redirect: 'follow',
            });
        });
    }
}
exports.default = Apis;
